import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Lazada from './pages/lazada'
import LazadaItemDetail from './pages/lazada/detail'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/lazada" />,
  },
  {
    path: "/lazada",
    element: <Lazada></Lazada>,
  },
  {
    path: "/lazada/:item_id",
    element: <LazadaItemDetail></LazadaItemDetail>,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
