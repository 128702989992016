import React from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Swal from 'sweetalert2'
const omsUrl = `https://oms.hili.asia`
//const omsUrl = `http://127.0.0.1:10101`



function Lazada() {
    const columns = [
        {
            name: 'Sku',
            selector: row => row.sku,
            cell: (row) => {


                return <div className="font-bold">{row.sku}</div>
            }
        },
        {
            name: 'Wms Stock at the moment',
            selector: row => row.wms_stock,
            cell: (row) => {
                if (row.wms_stock == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.wms_stock}</div>
            }

        },
        {
            name: 'Stock Ratio',
            selector: row => row.stock_ratio,
            cell: (row) => {
                if (row.stock_ratio == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.stock_ratio}</div>
            }

        },
        {
            name: 'Unship Stock',
            selector: row => row.unship_stock,
            cell: (row) => {
                if (row.unship_stock == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.unship_stock}</div>
            }

        },
        {
            name: 'Promotion Stock',
            selector: row => row.promotion_stock,
            cell: (row) => {
                if (row.promotion_stock == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.promotion_stock}</div>
            }

        },
        {
            name: 'Lazada at the moment Updated Stock',
            selector: row => row.updated_stock,
            cell: (row) => {
                if (row.updated_stock == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.updated_stock}</div>
            }

        },
        {
            name: 'Message from interface',
            selector: row => row.interface_response,
            cell: (row) => {
                if (row.interface_response == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.interface_response}</div>
            }
        },
        {
            name: 'latest Updated Date',
            selector: row => row.latest_updated_date,
            cell: (row) => {
                if (row.latest_updated_date == null) {
                    return <div>ไม่พบข้อมูล</div>
                }
                return <div>{moment(row.latest_updated_date).format('YYYY-MM-DD HH:mm:ss')}</div>
            }
        },
        {
            name: 'Actions',
            selector: row => row.latest_updated_date,
            cell: (row) => {
                return <ul className='p-2'>
                    <li className="p-1"><a href='#' onClick={() => { syncStock(row.sku) }}>ซิงค์สต๊อคทันที</a></li>
                    <li className="p-1"><a href={'/lazada/' + row.sku} target='_blank'>ดูประวัติการซิงค์สต๊อค</a></li>
                    <li className="p-1"><a href="#" onClick={() => { filterLog(row.sku) }}>โหลดข้อมูลอีกครั้ง</a></li>
                </ul>
            }
        }
    ];
    const [tableData, setTableData] = React.useState([]);
    const [filterSku, setFilterSku] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(50);
    const [totalRows, setTotalRows] = React.useState(0);
    const [detectionMode, setDetectionMode] = React.useState(false)
    const filterLog = async (sku = "") => {
        try {
            Swal.fire({
                title: 'กำลังทำการดึงข้อมูล...',
                allowOutsideClick: false,
                showCancelButton: false,
                showCloseButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                }
            });
            if (sku === "") {
                let oms_url = `${omsUrl}/api/channel/market_place/lazada/get.lazada-sync-log?app_key=5f8e1e8f-6606-46e2-bb60-a820819930d4&custkey=c4f2e654-da53-4832-bc99-fd096bd8f530`
                if (perPage !== "all") {
                    oms_url += `&page=${page}&perPage=${perPage}`
                }
                if (filterSku !== "") {
                    oms_url += `&filter=${filterSku}`
                }

                if (detectionMode === true) {
                    oms_url += `&detection=1`
                }

                console.log(oms_url);

                let response = await fetch(oms_url)
                let response_object = await response.json();
                setTableData([...response_object.data])
                setTotalRows(response_object.totalRecord)
            } else {
                let oms_url = `${omsUrl}/api/channel/market_place/lazada/get.lazada-sync-log-update/${sku}?app_key=5f8e1e8f-6606-46e2-bb60-a820819930d4&custkey=c4f2e654-da53-4832-bc99-fd096bd8f530`
                let response = await fetch(oms_url)
                let response_object = await response.json();
                if ('sku' in response_object) {
                    let data = []
                    let oldTableData = JSON.parse(JSON.stringify(tableData))
                    for (let tmpData of oldTableData) {
                        if (tmpData.sku === response_object.sku) {
                            tmpData.wms_stock = response_object.wms_stock
                            tmpData.stock_ratio = response_object.stock_ratio
                            tmpData.unship_stock = response_object.unship_stock
                            tmpData.promotion_stock = response_object.promotion_stock
                            tmpData.updated_stock = response_object.updated_stock
                            tmpData.result = response_object.result
                            tmpData.interface_response = response_object.interface_response
                            tmpData.latest_updated_date = response_object.latest_updated_date
                        }

                        data.push(tmpData)
                    }

                    setTableData([...data])
                    Swal.fire({
                        title: "สำเร็จ",
                        text: "อัพเดทข้อมูลล่าสุดแล้ว",
                        icon: "info"
                    });
                } else {
                    Swal.fire({
                        title: "ไม่พบข้อมูล",
                        text: "ไม่พบข้อมูลการซิงค์สต๊อคของสินค้าชิ้นนี้",
                        icon: "info"
                    });
                }
            }
            Swal.close();
        } catch (err) {
            console.log(err)
            Swal.close();
        }


    }

    const syncStock = (sku) => {
        Swal.fire({
            title: "ยืนยัน ?",
            text: "ต้องการ Sync Stock ทันทีใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "ยกเลิก",
            confirmButtonText: "ยืนยัน"
        }).then(async (result) => {
            if (result.isConfirmed) {
                let oms_url = `${omsUrl}/api/channel/market_place/lazada/post.all_stock/${sku}?app_key=5f8e1e8f-6606-46e2-bb60-a820819930d4&custkey=c4f2e654-da53-4832-bc99-fd096bd8f530`

                Swal.fire({
                    title: "สำเร็จ!",
                    text: "ดำเนินการเรียบร้อยแล้ว กรุณารอ 2-3 นาทีแล้วทำการตรวจสอบอีกครั้ง",
                    icon: "success"
                });
                let response = await fetch(oms_url)
                let response_object = await response.json();
                console.log(response_object)
            }
        });
        console.log(`sku: ${sku}`)
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            // Handle enter key press here
            filterLog();
            // You can call a function or perform any action here
        }
    };

    const subHeaderComponentMemo = React.useMemo(() => {

        return <div>
            <input type="checkbox" checked={detectionMode} onChange={(e) => { setDetectionMode(e.target.checked) }} />  <label className='text-sm mr-5'>แสดงข้อมูลที่คาดว่าจะผิดพลาด</label>
            <input
                className="border border-gray-300 rounded-md p-1 pl-2 pr-2"
                type="text"
                placeholder='Search sku...'
                value={filterSku}
                onChange={(e) => { setFilterSku(e.target.value) }}
                onKeyUp={handleKeyDown}
            />
        </div>;
    }, [filterSku, detectionMode]);

    const handlePerRowsChange = (perRows) => {
        if (perRows === 1) {
            setPerPage('all')
            return
        }
        setPerPage(perRows)

    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    const onRowDoubleClicked = (rows) => {
        console.log(rows)
        window.open('/lazada/' + rows.sku, '_blank');
    }
    React.useEffect(() => {
        filterLog();
    }, [page, perPage, detectionMode])
    return (

        <div className="container mx-auto">

            <div className="grid grid-cols-1">
                <div className="col-span-1">
                    <div className="bg-white shadow-md p-4 overflow-x-auto">
                        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="col-span-1 md:col-span-1">
                                <div className="form-group">
                                    <label className="block mb-1">Sku :</label>
                                    <input type="text" className="form-input" value="55" />
                                </div>
                            </div>
                        </div> */}
                        <DataTable
                            title="Lazada Sync Log"
                            className="overflow-hidden"
                            responsive
                            fixedHeader
                            persistTableHead
                            striped
                            columns={columns}
                            data={tableData}
                            noHeader={false}
                            subHeader subHeaderComponent={subHeaderComponentMemo}
                            onRowDoubleClicked={onRowDoubleClicked}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationComponentOptions={{
                                selectAllRowsItem: true,
                            }}
                            paginationRowsPerPageOptions={[50, 100, 200, 500]}

                        >

                        </DataTable>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default Lazada;