import React from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useParams } from 'react-router-dom';
const omsUrl = `https://oms.hili.asia`
//const omsUrl = `http://127.0.0.1:10101`
function Detail() {
    const { item_id } = useParams();
    const columns = [
        {
            name: 'Sku',
            selector: row => row.sku,
            cell: (row) => {


                return <div className="font-bold">{row.sku}</div>
            }
        },
        {
            name: 'Wms Stock at the moment',
            selector: row => row.wms_stock,
            cell: (row) => {
                if (row.wms_stock == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.wms_stock}</div>
            }

        },
        {
            name: 'Stock Ratio',
            selector: row => row.stock_ratio,
            cell: (row) => {
                if (row.stock_ratio == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.stock_ratio}</div>
            }

        },
        {
            name: 'Unship Stock',
            selector: row => row.unship_stock,
            cell: (row) => {
                if (row.unship_stock == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.unship_stock}</div>
            }

        },
        {
            name: 'Promotion Stock',
            selector: row => row.promotion_stock,
            cell: (row) => {
                if (row.promotion_stock == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.promotion_stock}</div>
            }

        },
        {
            name: 'Lazada at the moment Updated Stock',
            selector: row => row.updated_stock,
            cell: (row) => {
                if (row.updated_stock == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.updated_stock}</div>
            }

        },
        {
            name: 'Message from interface',
            selector: row => row.interface_response,
            cell: (row) => {
                if (row.interface_response == null) {
                    return <div>ไม่พบข้อมูล</div>
                }

                return <div>{row.interface_response}</div>
            }
        },
        {
            name: 'latest Updated Date',
            selector: row => row.latest_updated_date,
            cell: (row) => {
                if (row.latest_updated_date == null) {
                    return <div>ไม่พบข้อมูล</div>
                }
                return <div>{moment(row.latest_updated_date).format('YYYY-MM-DD HH:mm:ss')}</div>
            }
        },

    ];
    const [tableData, setTableData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(50);
    const [totalRows, setTotalRows] = React.useState(0);
    const filterLog = async () => {
        let oms_url = `${omsUrl}/api/channel/market_place/lazada/get.lazada-sync-log/${item_id}?app_key=5f8e1e8f-6606-46e2-bb60-a820819930d4&custkey=c4f2e654-da53-4832-bc99-fd096bd8f530`
        if (perPage !== "all") {
            oms_url += `&page=${page}&perPage=${perPage}`
        }


        console.log(oms_url);

        let response = await fetch(oms_url)
        let response_object = await response.json();
        setTableData([...response_object.data])
        setTotalRows(response_object.totalRecord)
    }
    const handlePerRowsChange = (perRows) => {
        if (perRows === 1) {
            setPerPage('all')
            return
        }
        setPerPage(perRows)

    }

    const handlePageChange = (page) => {
        setPage(page)
    }

    const onRowDoubleClicked = (rows) => {
        console.log(rows)
        window.open(`/lazada/${rows.sku}`, '_blank');
    }
    React.useEffect(() => {
        filterLog();
    }, [page, perPage])
    return (
        <div className="container mx-auto">

            <div className="grid grid-cols-1">
                <div className="col-span-1">
                    <div className="bg-white shadow-md p-4 overflow-x-auto">
                        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="col-span-1 md:col-span-1">
                            <div className="form-group">
                                <label className="block mb-1">Sku :</label>
                                <input type="text" className="form-input" value="55" />
                            </div>
                        </div>
                    </div> */}
                        <DataTable
                            title={"Lazada Sync Log : " + item_id}
                            className="overflow-hidden"
                            responsive
                            fixedHeader
                            persistTableHead
                            striped
                            columns={columns}
                            data={tableData}
                            noHeader={false}
                            onRowDoubleClicked={onRowDoubleClicked}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationComponentOptions={{
                                selectAllRowsItem: true,
                            }}
                            paginationRowsPerPageOptions={[50, 100, 200, 500]}

                        >

                        </DataTable>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Detail;